import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginLayout from './Layout/LoginLayout';
import Login from './Page/login';
import List from './Page/Company/List'
import Dashboard from './Page/Dashboard'
import AddCompany from './Page/Company/AddCompany';
import EditCompany from './Page/Company/EditCompany';
import { getToken } from './utils'
import { useSelector } from 'react-redux';
import SubscriptionPlans from './Page/SubscriptionPlans';
import EditSubscriptionPlan from './Page/SubscriptionPlans/EditSubscriptionPlan';
import AddSubscriptionPlan from './Page/SubscriptionPlans/AddSubscriptionPlan';
import HitPay from './Page/hitPay';
import Routing from "./Routes/index"

function App() {


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/*' element={<Routing />} />
      </Routes>
    </div>
  );
}

export default App;
