
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { Paper, Stack, Typography, Box, Button, PaginationItem, Pagination, OutlinedInput } from '@mui/material';
import { StyledTableCell, PrimaryTypography } from '../../Theme/theme';
import axiosInstance from '../../Service/apiservice';
import AlertModal from '../../Component/modal';




let deleteModalDatas = {
    label: 'Confirm to delete Plan permanently',
    descriptionOne: 'Are you want delete Plan ?',
    buttonOne: {
        variant: 'outlined',
        color: '#0E5E84',
        backgroundColor: '#FFFFFF',
        text: 'Cancel'
    },
    buttonTwo: {
        variant: 'contained',
        color: '#FFFFFF',
        backgroundColor: '#B42318',
        text: 'Delete'
    },
}

export default function SubscriptionPlans() {
    const navigate = useNavigate()
    const [data, setData] = useState([])

    const [deleteId, setDeleteId] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)




    const getPlans = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/subscription_plan`)
            console.log(response);
            setData(response?.data?.data?.subscription_plan)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getPlans()
    }, [])

    const DeleteData = async () => {

        try {
            const response = await axiosInstance.delete(`${process.env.REACT_APP_WMS_API_BASE_URL}/subscription_plan/${deleteId}`)
            console.log(response);
            getPlans()
            closeModal()
        }
        catch (err) {
            console.log(err);
        }
    }

    const closeModal = () => {
        setDeleteModal(false)
        setDeleteId('')
    }

    return (<>

        {deleteModal && <AlertModal modalDatas={deleteModalDatas} confirmFunction={DeleteData} closeFunction={closeModal} />}

        <Box mb={3} display='flex' justifyContent='space-between'>
            <PrimaryTypography >Subscription Plans</PrimaryTypography>
            <Button variant='contained' onClick={() => navigate('/Company/AddSubscriptionPlan')}>Add</Button>
        </Box>
        <TableContainer sx={{ borderRadius: '12px' }} component={Paper}>
            <Table sx={{ minWidth: 650 }} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell >Plan Name</StyledTableCell>
                        <StyledTableCell >Duration</StyledTableCell>
                        <StyledTableCell >Amount</StyledTableCell>
                        <StyledTableCell >Plan Cycle</StyledTableCell>
                        <StyledTableCell ></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((itm, index) => {
                        return (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <StyledTableCell>{itm?.name}</StyledTableCell>
                                <StyledTableCell>{itm?.duration}</StyledTableCell>
                                <StyledTableCell>{itm?.amount}</StyledTableCell>
                                <StyledTableCell>{itm?.month == 1 ? 'month' : itm?.day == 1 ? 'day' : 'year'}</StyledTableCell>

                                <TableCell width='100px'>
                                    <Stack direction='row' gap={2} width='fit-content'>
                                        <Button variant='outlined' onClick={() => { navigate(`/Company/EditSubscriptionPlan/${itm?.id}`) }} pr={2} >Edit</Button>
                                        <Button variant="contained" color="error" onClick={() => { setDeleteId(itm?.id); setDeleteModal(true) }}>Delete</Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {/* <Box sx={{ backgroundColor: '#FFFFFF', border: '1px solid #E5E7EB', borderRadius: '0px 0px 8px 8px', display: 'flex', alignItems: 'center', justifyContent: 'end', p: 2.5 }}>
                <Pagination page={currentPage} size='large' count={Math.ceil(paginationData?.total / paginationData?.per_page)} onChange={(e, value) => setCurrentPage(value)} renderItem={(item) => (
                    <PaginationItem
                        // slots={{ previous: PreviousIcon, next: NextIcon }}
                        {...item}
                    />
                )}
                />
            </Box> */}
        </TableContainer>

    </>
    )
}
