
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginLayout from '../Layout/LoginLayout';
import Login from '../Page/login';
import List from '../Page/Company/List'
import Dashboard from '../Page/Dashboard'
import AddCompany from '../Page/Company/AddCompany';
import EditCompany from '../Page/Company/EditCompany';
import { getToken } from '../utils'
import { useSelector } from 'react-redux';
import SubscriptionPlans from '../Page/SubscriptionPlans';
import EditSubscriptionPlan from '../Page/SubscriptionPlans/EditSubscriptionPlan';
import AddSubscriptionPlan from '../Page/SubscriptionPlans/AddSubscriptionPlan';
import HitPay from '../Page/hitPay';
import EmailForm from '../Page/emailForm';


export default function Admin() {


    return (
        <>
            {/* <Dashboard> */}
            <Routes>
                <Route path='/Company' element={<Dashboard />}>
                    <Route index element={<List />} />
                    <Route path='AddCompany' element={<AddCompany />} />
                    <Route path="EditCompany/:id" element={<EditCompany />} />

                    {/* <Route path="SubscriptionPlans" > */}
                    <Route path="SubscriptionPlans" element={<SubscriptionPlans />} />
                    <Route path='AddSubscriptionPlan' element={<AddSubscriptionPlan />} />
                    <Route path="EditSubscriptionPlan /:id" element={<EditSubscriptionPlan />} />
                    <Route path='EmailForm' element={<EmailForm />} />
                    {/* </Route> */}
                </Route>
            </Routes>
            {/* </Dashboard> */}
        </>
    )
}
