import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { List, Stack, Menu, MenuItem, Collapse } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Navigate, Outlet } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import logo from '../Image/logo.png';
import subscription from '../Image/logo.png';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { getUserData } from '../utils';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { setLoginSliceToNull } from '../Redux/Feature/Login/LoginSlice';
import { useDispatch } from 'react-redux';
import Toaster from "../Component/Toaster"

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    // background: "linear-gradient(135deg, rgba(0, 223, 172, 1) 0%, rgba(0, 69, 159, 1) 78%)",
    backgroundColor: "#FFF",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        boxShadow: "0px 2px 4px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px 4px rgba(0, 0, 0, 0.14), 0px 1px 10px 4px rgba(0, 0, 0, 0.12) !important",
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [companyOpen, setCompanyOpen] = useState(false)
    const [UseranchorEl, setUseranchorEl] = useState(null)
    const UseranchorElOpen = Boolean(UseranchorEl)

    const handleResize = useDebouncedCallback(
        (entries) => {
        },
        200
    );

    useEffect(() => {
        const observer = new ResizeObserver(handleResize);
        observer.observe(document.body); // Replace with the element you want to observe
        return () => observer.disconnect();
    }, [handleResize]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logout = () => {
        localStorage.clear();
        navigate('/')
        dispatch(setLoginSliceToNull())
    }


    return (
        <>
            <Toaster />
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} sx={{ backgroundColor: 'none !important' }} >
                    <Toolbar sx={{ height: "55px !important", display: "flex", width: '100%' }} >
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography variant="h6" noWrap component="div"> */}
                        <Box flexGrow={1}>   {!open && < img src={logo} width="200px" height="100%" objectFit='cover' />}</Box>
                        {/* </Typography> */}
                        <Stack direction='row' alignItems='center' >
                            <Typography color="black" fontWeight='600' fontSize='18px'>{getUserData('name')}</Typography>
                            <AccountCircleIcon onClick={(e) => setUseranchorEl(e.currentTarget)} sx={{ color: '#405D6C', fontSize: "2.1rem", ml: 1 }} />
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <img src={logo} width="80%" height="90%" objectFit='cover' />

                        <IconButton onClick={handleDrawerClose} sx={{ backgroundColor: "#a4c5e342", ml: 1 }}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {getUserData('user_role') == 1 &&
                        <List>
                            <ListItem onClick={() => setCompanyOpen(!companyOpen)} key="company" disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <BusinessIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Company" sx={{ opacity: open ? 1 : 0 }} />
                                    {open ? !companyOpen ? <ExpandMoreIcon /> : <ExpandLessIcon /> : null}

                                </ListItemButton>
                            </ListItem>
                            <Collapse in={companyOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={() => navigate('/Company')} sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            {/* <TbUsers fontSize="1.3rem" /> */}
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: "black", listStyle: "none", textDecoration: "none" }} primary="List" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => navigate('AddCompany')} sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            {/* <PiNotepad fontSize="1.4rem" /> */}
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: "black" }} primary="Add" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            {/* <Divider /> */}

                            <ListItem key="plan" disablePadding sx={{ display: 'block' }}>
                                <ListItemButton onClick={() => navigate('SubscriptionPlans')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '20px',
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SubscriptionsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Subscription Plan" sx={{ opacity: open ? 1 : 0 }} />
                                    {/* {open ? !companyOpen ? <ExpandMoreIcon /> : <ExpandLessIcon /> : null} */}

                                </ListItemButton>
                            </ListItem>
                            <ListItem key="mail" disablePadding sx={{ display: 'block' }}>
                                <ListItemButton onClick={() => navigate('emailForm')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '20px',
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Mail" sx={{ opacity: open ? 1 : 0 }} />
                                    {/* {open ? !companyOpen ? <ExpandMoreIcon /> : <ExpandLessIcon /> : null} */}

                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    {/* <Divider /> */}
                    {/* <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List> */}
                </Drawer>
                <Box minHeight='100vh' sx={{ backgroundColor: "#FAFAFC", flexGrow: 1, p: 3 }} component="main" >
                    <DrawerHeader />
                    <Outlet />
                </Box>

                <Menu open={UseranchorElOpen} anchorEl={UseranchorEl} onClose={() => setUseranchorEl(null)}>
                    <MenuItem onClick={() => { logout() }}>
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        </>

    );
}