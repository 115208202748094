import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Paper, Stack, Typography, Box, Button, OutlinedInput, Grid, IconButton, TextField, Select, MenuItem } from '@mui/material';
import { secondaryTypography, PrimaryTypography, ErrorTypography, SecondaryTypography } from '../../Theme/theme';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { fontSize } from '@mui/system';
import axiosInstance from '../../Service/apiservice';
import { useParams } from 'react-router-dom';
import { getChip } from "../../utils"

export default function EditCompany() {

    const navigate = useNavigate()
    const { id } = useParams()
    const [stsDDData, setstsDDData] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    const [planData, setPlanData] = useState([])
    const [status, setStatus] = useState(null)
    const [statusDetails, setStatusDetails] = useState(null)
    const [companyData, setCompanyData] = useState({
        company_name: '',
        slug: '',
        domain: '',
        official_email: '',
        status: "",
        phone: '',
        address: '',
        city: '',
        country: '',
        zipcode: '',
        description: '',
        first_name: "",
        last_name: "",
        email: '',
        plan: ''
    })
    const [errText, setErrText] = useState({})

    const [coverImg, setCoverImg] = useState(null)
    const coverimageRef = useRef()
    const [logoImg, setlogoImg] = useState(null)
    const logoimageRef = useRef()

    console.log(logoImg);
    console.log(coverImg);


    const handleChange = (e) => {
        const { name, value } = e.target
        setCompanyData((prev) => ({ ...prev, [name]: value }))

        setErrText((prev) => ({ ...prev, [name]: '' }))
    }

    const handlecoverImage = (e) => {
        const file = e.target.files[0]
        if (file) {
            setCoverImg({ file: file, url: URL.createObjectURL(file) })
            setErrText((prev) => ({ ...prev, coverImg: '' }))
        }
    }
    const handlelogoImage = (e) => {
        const file = e.target.files[0]
        if (file) {
            setlogoImg({ file: file, url: URL.createObjectURL(file) })
            setErrText((prev) => ({ ...prev, logoImg: '' }))
        }
    }

    const valid = (val) => {
        let isValid = true
        if (val == 'save') {
            for (const key in companyData) {
                if (key !== 'slug' && key !== 'domain' && key !== 'last_name' && key !== 'description') {
                    if (companyData[key] == '' || companyData[key] == null) {
                        setErrText((prev) => ({ ...prev, [key]: 'Required field !' }))
                        isValid = false
                    }
                }
                else {
                    setErrText((prev) => ({ ...prev, [key]: '', coverImg: '', logoImg: "" }))
                }
            }
        }
        else if (val == 'publish') {
            for (const key in companyData) {
                if (companyData[key] == '' || companyData[key] == null) {
                    setErrText((prev) => ({ ...prev, [key]: 'Required field !' }))
                    isValid = false
                }
            }
            if (coverImg == null) {
                setErrText((prev) => ({ ...prev, coverImg: 'please upload Image' }))
                isValid = false
            }
            if (logoImg == null) {
                setErrText((prev) => ({ ...prev, logoImg: 'please upload Image' }))
                isValid = false
            }
        }

        return isValid
    }


    const submit = (val) => {

        console.log(valid(val));
        if (valid(val)) {
            setDisableBtn(true)
            postData(val)
        }
    }



    const StatusDropDown = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/dropdown?type=company_status&company_id=${id}`)
            console.log(response);
            setstsDDData(response?.data?.data?.company_status)

        } catch (error) {
            console.log('dropdown:', error);
        }
    }

    const getPlans = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/subscription_plan`)
            console.log(response);
            setPlanData(response?.data?.data?.subscription_plan)
        }
        catch (err) {
            console.log(err);
        }
    }



    const getCompany = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/company/${id}`)
            console.log(response?.data?.data?.company);
            const data = response?.data?.data?.company
            setCompanyData((prev) => ({
                ...prev,
                company_name: data?.company_name,
                slug: data?.slug,
                domain: data?.domain,
                official_email: data?.official_email,
                phone: data?.phone,
                address: data?.address,
                city: data?.city,
                country: data?.country,
                zipcode: data?.zipcode,
                description: data?.description,
                first_name: data?.first_name,
                last_name: data?.last_name,
                email: data?.email,
                status: data?.status,
                plan: data?.company_plan?.plan_id
            }))
            setStatus(data?.status == 3 ? true : false)
            setStatusDetails(data?.status_details)
            setlogoImg({ url: process.env.REACT_APP_WMS_IMG_BASE_URL + data?.logo })
            setCoverImg({ url: process.env.REACT_APP_WMS_IMG_BASE_URL + data?.cover_image })

        }
        catch (err) {
            console.log(err);
        }
    }


    useEffect(() => {
        StatusDropDown()
        getCompany()
        getPlans()

    }, [])
    const postData = async (val) => {
        const data = new FormData()

        data.append('slug', companyData?.slug)
        data.append('company_name', companyData?.company_name)
        data.append('official_email', companyData?.official_email)
        data.append('domain', companyData?.domain)
        data.append('description', companyData?.description)
        { Boolean(logoImg?.file) && data.append('logo', logoImg?.file) }
        { Boolean(coverImg?.file) && data.append('cover_image', coverImg?.file) }
        data.append('first_name', companyData?.first_name)
        data.append('last_name', companyData?.last_name)
        data.append('email', companyData?.email)
        data.append('phone', companyData?.phone)
        data.append('country_code', '')
        data.append('address', companyData?.address)
        data.append('country', companyData?.country)
        data.append('city', companyData?.city)
        data.append('zipcode', companyData?.zipcode)
        data.append('status', companyData?.status)
        data.append('plan_id', companyData?.plan)
        { val == 'publish' && data.append('is_publish', true) }

        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_WMS_API_BASE_URL}/company/${id}`, data)
            console.log(response);
            navigate('/Company')
            setDisableBtn(false)
        } catch (error) {
            console.log(error);
            setDisableBtn(false)
            const err = error?.response?.data?.data?.validation_error
            for (const key in err) {
                setErrText((prev) => ({ ...prev, [key]: err[key] }))
            }
        }

    }



    return (
        <>
            <Stack mb={3} direction="row" justifyContnt='space-between' alignItems='center' sx={{ width: '100%' }}>
                <PrimaryTypography flexGrow={1} style={{ color: "rgb(33 91 85 / 87%)" }}>Company Details</PrimaryTypography>
                {getChip(statusDetails?.name)}
            </Stack>
            <Box mb={7} backgroundColor='#FFFFFF' p={2} sx={{ borderRadius: "8px", boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)", }}>
                <Grid container >
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Company Name</secondaryTypography>
                        <TextField name='company_name' onChange={(e) => handleChange(e)} value={companyData?.company_name} placeholder="Please enter company name" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.company_name}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Slug</secondaryTypography>
                        <TextField disabled={status} name='slug' onChange={(e) => handleChange(e)} value={companyData?.slug} placeholder="Please enter slug" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.slug}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Domain Name</secondaryTypography>
                        <TextField disabled={status} name='domain' onChange={(e) => handleChange(e)} value={companyData?.domain} placeholder="Please enter domain name" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.domain}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Official Email</secondaryTypography>
                        <TextField name='official_email' onChange={(e) => handleChange(e)} value={companyData?.official_email} placeholder="Please enter email" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.official_email}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <SecondaryTypography >Status</SecondaryTypography>
                        <Select name='status' onChange={(e) => handleChange(e)} value={companyData?.status} renderValue={companyData?.status !== "" ? undefined : () => "Select status"} InputProps={{ disableUnderline: true }} displayEmpty>
                            {stsDDData?.map((itm) => (
                                <MenuItem key={itm?.key} value={itm?.id}>{itm?.name}</MenuItem>
                            ))}
                        </Select>
                        {/* <TextField name='address' onChange={(e) => handleChange(e)} value={companyData?.address} placeholder="Please enter address" InputProps={{ disableUnderline: true }} /> */}
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.status}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Phone</secondaryTypography>
                        <TextField type='number' name='phone' onChange={(e) => handleChange(e)} value={companyData?.phone} placeholder="Please enter phone number" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.phone}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Address</secondaryTypography>
                        <TextField name='address' onChange={(e) => handleChange(e)} value={companyData?.address} placeholder="Please enter address" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.address}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>City</secondaryTypography>
                        <TextField name='city' onChange={(e) => handleChange(e)} value={companyData?.city} placeholder="Please enter city" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.city}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Country</secondaryTypography>
                        <TextField name='country' onChange={(e) => handleChange(e)} value={companyData?.country} placeholder="Please enter country" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.country}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>ZipCode</secondaryTypography>
                        <TextField name='zipcode' onChange={(e) => handleChange(e)} value={companyData?.zipcode} placeholder="Please enter zipcode" InputProps={{ disableUnderline: true }} inputProps={{ maxLength: 6 }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.zipcode}</ErrorTypography>
                    </Grid>
                    <Grid sm={12} p={2}>
                        <secondaryTypography mb={1.2}>Description</secondaryTypography>
                        <TextField name='description' onChange={(e) => handleChange(e)} value={companyData?.description} sx={{ minHeight: '100px' }} placeholder="Please enter description" InputProps={{ disableUnderline: true }} multiline />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.description}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <secondaryTypography mb={1.2}>Cover Image</secondaryTypography>
                        {coverImg == null ?
                            <>
                                <Box mt={1} onClick={() => coverimageRef.current.click()} sx={{ borderStyle: "dashed", borderColor: " #56b9dc", width: 'fit-content', borderRadius: "8px", borderWidth: "2px", padding: "5px 10px 0px 10px" }}>
                                    <CloudUploadIcon sx={{ fontSize: '2.5rem !important', color: "#56b9dc" }} />
                                </Box>
                            </>
                            :
                            <Box mt={1} sx={{ position: 'relative', borderStyle: "dashed", borderColor: " #56b9dc", width: 'fit-content', borderRadius: "8px", borderWidth: "2px", padding: "5px 10px 0px 10px" }}>
                                <img src={coverImg?.url} width='150px' height='150px' style={{ objectFit: 'cover' }} />
                                <IconButton onClick={() => setCoverImg(null)} sx={{ position: 'absolute', top: '-20px' }}>
                                    <ClearIcon sx={{ fontSize: '2rem !important', }} />
                                </IconButton>
                            </Box>
                        }
                        <input type='file' ref={coverimageRef} onChange={(e) => handlecoverImage(e)} hidden />

                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.coverImg}</ErrorTypography>

                    </Grid>
                    <Grid sm={4} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <secondaryTypography mb={1.2}>Logo Image</secondaryTypography>
                        {logoImg == null ?
                            <>
                                <Box mt={1} onClick={() => logoimageRef.current.click()} sx={{ borderStyle: "dashed", borderColor: " #56b9dc", width: 'fit-content', borderRadius: "8px", borderWidth: "2px", padding: "5px 10px 0px 10px" }}>
                                    <CloudUploadIcon sx={{ fontSize: '2.5rem !important', color: "#56b9dc" }} />
                                </Box>
                            </>
                            :
                            <Box mt={1} sx={{ position: 'relative', borderStyle: "dashed", borderColor: " #56b9dc", width: 'fit-content', borderRadius: "8px", borderWidth: "2px", padding: "5px 10px 0px 10px" }}>
                                <img src={logoImg?.url} width='150px' height='150px' style={{ objectFit: 'cover' }} />
                                <IconButton onClick={() => setlogoImg(null)} sx={{ position: 'absolute', top: '-20px' }}>
                                    <ClearIcon sx={{ fontSize: '2rem !important', }} />
                                </IconButton>
                            </Box>
                        }
                        <input type='file' ref={logoimageRef} onChange={(e) => handlelogoImage(e)} hidden />

                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.logoImg}</ErrorTypography>

                    </Grid>
                </Grid>
            </Box>
            <h2 style={{ color: "rgb(33 91 85 / 87%)" }}>Incharge Details</h2>
            <Box mb={5} backgroundColor='#FFFFFF' p={2} sx={{ borderRadius: "8px", boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)" }}>
                <Grid container >
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>First Name</secondaryTypography>
                        <TextField name='first_name' onChange={(e) => handleChange(e)} value={companyData?.first_name} placeholder="Please enter first name" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.first_name}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Last Name</secondaryTypography>
                        <TextField name='last_name' onChange={(e) => handleChange(e)} value={companyData?.last_name} placeholder="Please enter last name" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.last_name}</ErrorTypography>
                    </Grid>
                    <Grid sm={4} p={2}>
                        <secondaryTypography mb={1.2}>Email</secondaryTypography>
                        <TextField name='email' onChange={(e) => handleChange(e)} value={companyData?.email} placeholder="Please enter email" InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.email}</ErrorTypography>
                    </Grid>
                </Grid>
            </Box>
            <PrimaryTypography mb={3}>Plan Details</PrimaryTypography>

            <Box mb={5} backgroundColor='#FFFFFF' p={2} sx={{ borderRadius: "8px", boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)" }}>
                <Grid container >
                    <Grid sm={4} p={2}>
                        <SecondaryTypography >Plan</SecondaryTypography>
                        <Select name='plan' onChange={(e) => handleChange(e)} value={companyData?.plan} renderValue={companyData?.plan !== "" ? undefined : () => "Select plan"} InputProps={{ disableUnderline: true }} displayEmpty>
                            {planData?.map((itm) => (
                                <MenuItem key={itm?.key} value={itm?.id}>{itm?.name}</MenuItem>
                            ))}
                        </Select>
                        <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errText?.plan}</ErrorTypography>
                    </Grid>
                </Grid>
            </Box>
            <Stack mb={5} direction='row' columnGap={2} justifyContent='end' alignItems='center'>
                {companyData?.status == (1 || 2) && <Button variant='outlined' onClick={() => submit('publish')} disabled={disableBtn}>Publish</Button>}
                <Button variant='contained' onClick={() => submit('save')} disabled={disableBtn}>Save</Button>
            </Stack>
        </>
    )
}
