
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { Paper, Stack, Typography, Box, Button, PaginationItem, Pagination, OutlinedInput } from '@mui/material';
import { PrimaryTypography, secondaryTypography, StyledTableCell } from '../../Theme/theme';
import axiosInstance from '../../Service/apiservice';
import AlertModal from '../../Component/modal';




let deleteModalDatas = {
    label: 'Confirm to delete company permanently',
    descriptionOne: 'Are you want delete company ?',
    buttonOne: {
        variant: 'outlined',
        color: '#0E5E84',
        backgroundColor: '#FFFFFF',
        text: 'Cancel'
    },
    buttonTwo: {
        variant: 'contained',
        color: '#FFFFFF',
        backgroundColor: '#B42318',
        text: 'Delete'
    },
}

export default function List() {
    const navigate = useNavigate()

    const [data, setdata] = useState([])
    const [paginationData, setPaginationData] = useState({})

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState('5')
    const [deleteId, setDeleteId] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)


    useEffect(() => {

        getCompany()

    }, [currentPage])

    const getCompany = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/company`)
            console.log(response);
            setdata(response?.data?.data?.companies)
            setPaginationData(response?.data?.data?.pagination_details)
        }
        catch (err) {
            console.log(err);
        }
    }

    const DeleteData = async () => {

        try {
            const response = await axiosInstance.delete(`${process.env.REACT_APP_WMS_API_BASE_URL}/company/${deleteId}`)
            console.log(response);
            getCompany()
            closeModal()


        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = () => {
        setDeleteModal(false)
        setDeleteId('')
    }

    return (<>

        {deleteModal && <AlertModal modalDatas={deleteModalDatas} confirmFunction={DeleteData} closeFunction={closeModal} />}

        <Box mb={3} display='flex' justifyContent='space-between'>
            <PrimaryTypography >Company List</PrimaryTypography>
            <Button variant='contained' onClick={() => navigate('AddCompany')}>Add</Button>
        </Box>
        <TableContainer sx={{ borderRadius: '12px' }} component={Paper}>
            <Table sx={{ minWidth: 650 }} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell >Logo</StyledTableCell>
                        <StyledTableCell >Company Name</StyledTableCell>
                        <StyledTableCell >Slug</StyledTableCell>
                        <StyledTableCell >Domain</StyledTableCell>
                        <StyledTableCell >Status</StyledTableCell>
                        <StyledTableCell ></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((itm, index) => {
                        return (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <img src={`${process.env.REACT_APP_WMS_IMG_BASE_URL}${itm?.cover_image}`} alt="" width="70px" height="70px" style={{ objectFit: 'cover' }} />
                                </TableCell>
                                <StyledTableCell>{itm?.company_name}</StyledTableCell>
                                <StyledTableCell>{itm?.slug}</StyledTableCell>
                                <StyledTableCell>{itm?.domain}</StyledTableCell>
                                <StyledTableCell>{itm?.status_details?.name}</StyledTableCell>

                                <TableCell width='100px'>
                                    <Stack direction='row' gap={2} width='fit-content'>
                                        <Button variant='outlined' onClick={() => { navigate(`EditCompany/${itm?.id}`) }} pr={2} >Edit</Button>
                                        <Button variant="contained" color="error" onClick={() => { setDeleteId(itm?.id); setDeleteModal(true) }}>Delete</Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Box sx={{ backgroundColor: '#FFFFFF', border: '1px solid #E5E7EB', borderRadius: '0px 0px 8px 8px', display: 'flex', alignItems: 'center', justifyContent: 'end', p: 2.5 }}>
                <Pagination page={currentPage} size='large' count={Math.ceil(paginationData?.total / paginationData?.per_page)} onChange={(e, value) => setCurrentPage(value)} renderItem={(item) => (
                    <PaginationItem
                        // slots={{ previous: PreviousIcon, next: NextIcon }}
                        {...item}
                    />
                )}
                />
            </Box>
        </TableContainer>

    </>
    )
}
