import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorTypography, PrimaryTypography, SecondaryTypography } from '../Theme/theme';
import { Paper, Stack, Typography, Box, Button, MenuItem, OutlinedInput, Grid, Autocomplete, Select, IconButton, Chip, TextField, Modal } from '@mui/material';
import logo from '../Image/logo.png';
import { emailValidator } from '../utils/Validation';
import axiosInstance from '../Service/apiservice';
import SendIcon from '@mui/icons-material/Send';
import { showToast } from '../Component/Toaster';

const EmailForm = () => {

    const initialState = { name: '', email: '', cc: '' }
    const [data, setdata] = useState(initialState)
    const [err, setErr] = useState(initialState)
    const hostinitialState = { host: '', port: '', email: '', password: '' }
    const [hostData, sethostData] = useState(hostinitialState)
    const [hosterr, sethostErr] = useState(hostinitialState)
    const [hostModal, setHostModal] = useState(false)
    const [testmail, setTestmail] = useState('')


    const validConfigs = {
        name: (name) => { if (name.length === '') { return false } else { return true } },
        email: emailValidator,
        cc: emailValidator
    }

    const validHostConfigs = {
        host: (name) => { if (name.length === '') { return false } else { return true } },
        port: (name) => { if (name.length === '') { return false } else { return true } },
        email: emailValidator,
        password: (name) => { if (name.length === '') { return false } else { return true } },
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setdata((prev) => ({ ...prev, [name]: value }))
        setErr((prev) => ({ ...prev, [name]: '' }))
    }

    const handleChangeHost = (e) => {
        const { name, value } = e.target
        sethostData((prev) => ({ ...prev, [name]: value }))
        sethostErr((prev) => ({ ...prev, [name]: '' }))
    }

    const validate = () => {
        let isError = false
        for (const key in data) {
            if (data[key] == '') {
                setErr((prev) => ({ ...prev, [key]: 'Required field !' }))
                isError = true
            }
            else if (validConfigs[key](data[key]) === false) {
                setErr((prev) => ({ ...prev, [key]: 'Invalid Input' }))
                isError = true
            }
        }
        return isError;
    }

    const validatehost = () => {
        let isError = false
        for (const key in hostData) {
            if (hostData[key] == '') {
                sethostErr((prev) => ({ ...prev, [key]: 'Required field !' }))
                isError = true
            }
            else if (validHostConfigs[key](hostData[key]) === false) {
                sethostErr((prev) => ({ ...prev, [key]: 'Invalid Input' }))
                isError = true
            }
        }
        return isError;
    }

    const Sendmail = async (val) => {
        const type = Boolean(val)
        const bodyData = type ? { name: '', email: testmail, cc: '' } : { name: data?.name, email: data?.email, cc: data?.cc }

        if (type ? true : !validate()) {
            try {
                const response = await axios.post('https://bsmartmailer.demopsts.com/SendMail', bodyData)
                console.log(response);
                if (response.status == 200) {
                    setdata(initialState)
                    setTestmail('')
                    showToast(response?.data?.message)
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const Submithost = async () => {
        if (!validatehost()) {
            try {
                const response = await axios.post('https://bsmartmailer.demopsts.com/postHostData', { host: hostData?.host, port: hostData?.port, email: hostData?.email, password: hostData?.password })
                console.log(response);
                if (response.status == 200) {
                    // sethostData(hostinitialState)
                    gethostData()
                    handleCloseModal()
                    showToast(response?.data?.message)

                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const gethostData = async () => {
        try {
            const response = await axios.get('https://bsmartmailer.demopsts.com/getHostData')
            console.log(response);
            const data = response?.data?.data
            sethostData({ host: data?.host, port: data?.port, email: data?.email, password: data?.password })

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        gethostData()

    }, [])


    const handleCloseModal = () => {
        setHostModal(false)
        setdata(initialState)
    }


    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
                <Box mb={7} backgroundColor='#FFFFFF' p={3} sx={{ width: { md: '60%', xs: '90%' }, borderRadius: "8px", boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)", position: 'relative' }}>
                    <Button variant='contained' sx={{ position: 'absolute', right: '0', top: '-60px' }} onClick={() => setHostModal(true)} >Edit Host</Button>
                    <PrimaryTypography textAlign='center'>Email Form</PrimaryTypography>
                    <Grid my={3} container>
                        <Grid xs={12} >
                            <SecondaryTypography >Client Name</SecondaryTypography>
                            <TextField name='name' value={data?.name} placeholder="Please enter Client name" onChange={(e) => handleChange(e)} InputProps={{ disableUnderline: true }} />
                            <ErrorTypography mt={1} >{err?.name}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} mt={2}>
                            <SecondaryTypography >Client Email</SecondaryTypography>
                            <TextField name='email' value={data?.email} placeholder="Please enter Client email" onChange={(e) => handleChange(e)} InputProps={{ disableUnderline: true }} />
                            <ErrorTypography mt={1} >{err?.email}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} mt={2}>
                            <SecondaryTypography >cc</SecondaryTypography>
                            <TextField name='cc' value={data?.cc} placeholder="Please enter cc" onChange={(e) => handleChange(e)} InputProps={{ disableUnderline: true }} />
                            <ErrorTypography mt={1} >{err?.cc}</ErrorTypography>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant='contained' onClick={() => Sendmail()}>Send</Button>
                    </Box>
                </Box>
            </Box>

            <Modal open={hostModal} onClose={handleCloseModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', outline: "none", p: 4, }}>

                    <Grid xs={12} >
                        <SecondaryTypography >Host</SecondaryTypography>
                        <TextField name='host' value={hostData?.host} placeholder="Please enter Host" onChange={(e) => handleChangeHost(e)} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} >{hosterr?.host}</ErrorTypography>
                    </Grid>
                    <Grid xs={12} >
                        <SecondaryTypography >Port no</SecondaryTypography>
                        <TextField name='port' value={hostData?.port} placeholder="Please enter Port no" onChange={(e) => handleChangeHost(e)} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} >{hosterr?.port}</ErrorTypography>
                    </Grid><Grid xs={12} >
                        <SecondaryTypography >Email</SecondaryTypography>
                        <TextField name='email' value={hostData?.email} placeholder="Please enter Email" onChange={(e) => handleChangeHost(e)} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} >{hosterr?.email}</ErrorTypography>
                    </Grid>
                    <Grid xs={12} >
                        <SecondaryTypography >password</SecondaryTypography>
                        <TextField name='password' value={hostData?.password} placeholder="Please enter password" onChange={(e) => handleChangeHost(e)} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography mt={1} >{hosterr?.password}</ErrorTypography>
                    </Grid>
                    <Grid xs={12} >
                        <SecondaryTypography >test mail</SecondaryTypography>
                        <Box columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField width='70% !important' value={testmail} name='email' placeholder="Please enter test email" onChange={(e) => setTestmail(e?.target?.value)} InputProps={{ disableUnderline: true }} />
                            <Button onClick={() => Sendmail('test')} sx={{ mt: 1, backgroundColor: "#2f4f4fe8", color: 'white', padding: '8px 14px', height: '35px', fontSize: '15px !important', '&:hover': { backgroundColor: "#2f4f4fe8", color: 'white', } }} endIcon={<SendIcon style={{ fontSize: '1rem' }} />}>Send</Button>
                        </Box>
                        <ErrorTypography mt={1} >{hosterr?.password}</ErrorTypography>
                    </Grid>
                    <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant='danger' onClick={handleCloseModal} >Cancel</Button>
                        <Button variant='contained' onClick={Submithost}>Edit</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default EmailForm
